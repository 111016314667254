import React from "react";
import { Modal, Button } from "@material-ui/core";
import { useHandleResize } from "../../utils";

export const FallbackComponent = () => {
  const { width, height } = useHandleResize();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width < 920 ? "95vw" : "50%",
    maxWidth: width < 920 ? "95vw" : "50%",
    maxHeight: height < 750 ? "95vh" : "none",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: width < 400 ? "24px 16px" : 30,
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
    outlineStyle: "none",
  };
  return (
    <div>
      <Modal title="Erreur" open>
        <div className="contactFormModal" style={style}>
          <p>Une erreur vient de se produire.</p>

          <p>
            Un rapport détaillé vient d&apos;être envoyé à notre équipe afin que
            nous puissions améliorer notre logiciel.
          </p>

          <p>
            Si l&apos;erreur persiste, n&apos;hésitez pas à nous contacter
            directement.
          </p>

          <p>En vous remerciant pour votre compréhension</p>
          <Button type="primary" onClick={() => window.open("/", "_self")}>
            Retour à l&apos;accueil
          </Button>
        </div>
      </Modal>
    </div>
  );
};
