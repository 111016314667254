// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ma-visite-js": () => import("./../../../src/pages/ma-visite.js" /* webpackChunkName: "component---src-pages-ma-visite-js" */),
  "component---src-pages-reservation-groupe-js": () => import("./../../../src/pages/reservation-groupe.js" /* webpackChunkName: "component---src-pages-reservation-groupe-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-rgpd-js": () => import("./../../../src/pages/rgpd.js" /* webpackChunkName: "component---src-pages-rgpd-js" */)
}

